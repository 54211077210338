import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, IconButton } from "@mui/material";
import { doc, increment, serverTimestamp, updateDoc } from "firebase/firestore";
import { FC } from "react";
import { db } from "../../../..";
import { getProblem3rd } from "../../data/prob";
import { blue, green, red, yellow } from "../../theme";
import { StageProps } from "../AdminNazoClusterQuiz";

type ProbCursor = {
  genre: number;
  probNumber: number;
};

export const Stage3rd: FC<StageProps> = (props) => {
  const { users, roomId, room } = props;

  const stage3 = room.stage3;
  const updateUser = (userId: string, teamId: number, teamNumber: number) => {
    if (!roomId) return;
    updateDoc(doc(db, "nazoClusterQuiz/" + roomId + "/user/" + userId), {
      teamId,
      teamNumber,
    });
  };

  const updatePhase = (phase: string) => {
    if (!roomId) return;
    if (!room) return;

    if (phase === "playing") {
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stage3.phase": phase,
        "stage3.startTime": serverTimestamp(),
      });
    }
    if (phase === "finished") {
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stage3.phase": phase,
        "stage3.endTime": serverTimestamp(),
      });
    }
    if (phase === "explaining") {
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stage3.phase": phase,
      });
    }
    if (phase === "calculated") {
      // every correct
      const teamSuccess = [1, 2, 3, 4].map((teamId) => {
        const collectStatus = [false, false, false];

        users
          .filter((u) => u.teamId === teamId)
          .forEach((u) => {
            const prob = getProblem3rd(stage3?.probNumber ?? 1);
            const answer = prob?.answer ?? "";
            const userSubmit = room.stage3?.probNumber
              ? u.stage3?.[room.stage3?.probNumber]
              : undefined;
            if (userSubmit?.correctStatus === undefined) {
              const id = answer
                .split(",")
                .findIndex((e) => e === (userSubmit?.answer ?? ""));
              if (id !== undefined) {
                collectStatus[id] = true;
              }
            } else {
              if (userSubmit?.correctStatus !== -1) {
                collectStatus[userSubmit?.correctStatus] = true;
              }
            }
          });
        return collectStatus;
      });
      const defaultProb = getProblem3rd(stage3?.probNumber ?? 1);
      const teamScores = teamSuccess.map((success, i) =>
        success.reduce(
          (acc, cur) => acc + (cur ? defaultProb?.score ?? 0 : 0),
          0
        )
      );
      console.log(teamSuccess, teamScores);

      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stage3.phase": phase,
        "stage3.calcTime": serverTimestamp(),

        ["stage3.team.1.problem." +
        (stage3?.probNumber ?? 1).toString() +
        ".score"]: teamScores[0],
        ["stage3.team.2.problem." +
        (stage3?.probNumber ?? 1).toString() +
        ".score"]: teamScores[1],
        ["stage3.team.3.problem." +
        (stage3?.probNumber ?? 1).toString() +
        ".score"]: teamScores[2],
        ["stage3.team.4.problem." +
        (stage3?.probNumber ?? 1).toString() +
        ".score"]: teamScores[3],
        "stage3.team.1.score": increment(teamScores[0]),
        "stage3.team.2.score": increment(teamScores[1]),
        "stage3.team.3.score": increment(teamScores[2]),
        "stage3.team.4.score": increment(teamScores[3]),
      });
    }
  };

  const updateOpen = (userId: string) => {
    if (!roomId) return;
    if (!room) return;
    updateDoc(doc(db, "nazoClusterQuiz/" + roomId + "/user/" + userId), {
      ["stage3." + stage3?.probNumber + ".open"]: true,
    });
  };

  const updateCorrectStatus = (userId: string, correct: number) => {
    if (!roomId) return;
    if (!room) return;
    updateDoc(doc(db, "nazoClusterQuiz/" + roomId + "/user/" + userId), {
      ["stage3." + stage3?.probNumber + ".correctStatus"]: correct,
    });
  };

  const updateNextProblem = () => {
    if (!roomId) return;
    if (!room) return;
    if (!stage3) return;

    updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
      "stage3.probNumber": increment(1),
      "stage3.phase": "waiting",
    });
  };

  return (
    <>
      <h2>ステージ3</h2>
      <div style={{ textAlign: "center", marginBottom: "8px" }}>
        <p>問題管理</p>
        {(stage3?.phase ?? "waiting") === "waiting" ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updatePhase("playing");
              }}
            >
              スタート
            </Button>
          </>
        ) : stage3?.phase === "playing" ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updatePhase("finished");
              }}
            >
              解答終了
            </Button>
          </>
        ) : stage3?.phase === "finished" ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updatePhase("explaining");
              }}
            >
              解説表示
            </Button>
          </>
        ) : stage3?.phase === "explaining" ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updatePhase("calculated");
              }}
            >
              得点集計
            </Button>
          </>
        ) : stage3?.phase === "calculated" ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updateNextProblem();
              }}
            >
              次の問題へ
            </Button>
          </>
        ) : (
          <></>
        )}
        <p>
          {getProblem3rd(stage3?.probNumber ?? 1)
            ?.answer.split(",")
            .map((ans, i) => `${i + 1}:${ans}`)
            .join(" ")}
        </p>
      </div>
      <table
        style={{
          margin: "auto",
          border: "1px solid #000",
          borderCollapse: "collapse",
          borderSpacing: 0,
          fontSize: "100%",
        }}
      >
        <tr style={trStyle}>
          <th style={thStyle}>チーム</th>
          <th style={thStyle}>1st</th>
          <th style={thStyle}>2nd</th>
          <th style={thStyle}>3rd</th>
          <th style={thStyle}>一括</th>
          <th style={thStyle}>スコア</th>
        </tr>
        {[1, 2, 3, 4].map((teamId) => (
          <tr key={teamId}>
            <th
              style={{
                ...thStyle,
                ...teamHeaderStyle,
                backgroundColor: [red, blue, green, yellow][teamId - 1],
              }}
            >
              チーム{teamId}
            </th>
            {[1, 2, 3].map((teamNumber) => (
              <td
                key={teamNumber}
                style={{
                  ...tdStyle,
                }}
              >
                {users
                  .filter(
                    (u) => u.teamId === teamId && u.teamNumber === teamNumber
                  )
                  .map((u, i) => {
                    const userSubmit = room.stage3?.probNumber
                      ? u.stage3?.[room.stage3?.probNumber]
                      : undefined;
                    const userAnswer = userSubmit?.answer ?? "";
                    const prob = getProblem3rd(stage3?.probNumber ?? 1);
                    const answer = prob?.answer ?? "";
                    return (
                      <>
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "#222",
                            color: "#fff",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              padding: 0,
                              textAlign: "center",
                            }}
                          >
                            {userSubmit?.open ? "オープン" : "クローズ中"}
                          </p>
                        </div>
                        <p>『{userAnswer}』</p>
                        <p
                          key={i}
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {u.name}
                        </p>
                        <p
                          style={{
                            margin: 0,
                          }}
                        >
                          <IconButton
                            aria-label="wrong"
                            color={
                              (
                                userSubmit?.correctStatus === undefined
                                  ? !answer.split(",").includes(userAnswer)
                                  : userSubmit?.correctStatus === -1
                              )
                                ? "info"
                                : "inherit"
                            }
                            onClick={() => {
                              updateCorrectStatus(u.id, -1);
                            }}
                          >
                            <CancelIcon />
                          </IconButton>
                          <>
                            {[0, 1, 2].map((status) => (
                              <IconButton
                                key={status}
                                aria-label="correct"
                                color={
                                  (
                                    userSubmit?.correctStatus === undefined
                                      ? answer
                                          .split(",")
                                          .findIndex(
                                            (e) => e === userAnswer
                                          ) === status
                                      : userSubmit?.correctStatus === status
                                  )
                                    ? "error"
                                    : "inherit"
                                }
                                onClick={() => {
                                  updateCorrectStatus(u.id, status);
                                }}
                              >
                                {status + 1}
                              </IconButton>
                            ))}
                          </>
                        </p>
                        {!userSubmit?.open && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              updateOpen(u.id);
                            }}
                          >
                            オープン
                          </Button>
                        )}
                      </>
                    );
                  })}
              </td>
            ))}
            <td
              style={{
                ...tdStyle,
              }}
            >
              {users
                .filter((u) => u.teamId === teamId)
                .some((u) => {
                  const userSubmit = room.stage3?.probNumber
                    ? u.stage3?.[room.stage3?.probNumber]
                    : undefined;
                  return !userSubmit?.open;
                }) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    users
                      .filter((u) => u.teamId === teamId)
                      .forEach((u) => {
                        updateOpen(u.id);
                      });
                  }}
                >
                  オープン
                </Button>
              )}
            </td>
            <td
              style={{
                ...tdStyle,
              }}
            >
              {stage3?.team[teamId]?.score ?? 0}
            </td>
          </tr>
        ))}
      </table>
      <Box sx={{ my: 8 }} />
    </>
  );
};

const trStyle = {
  border: "1px solid #000",
};

const thStyle = {
  border: "1px solid #000",
  backgroundColor: "#444",
  color: "white",
};

const tdStyle = {
  border: "1px solid #000",
  minWidth: "100px",
  color: "#111",
};

const teamHeaderStyle = {
  color: "white",
};
