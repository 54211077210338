import { Grid } from "@mui/material";
import { doc, serverTimestamp, Timestamp, updateDoc } from "firebase/firestore";
import { CSSProperties, FC, useEffect, useState } from "react";
import { db } from "../../../..";
import { getServerTime } from "../../../../lib/getServerTime";
import { getProblemFinal } from "../../data/prob";
import { blue, green, mainBg, mainColor, red, yellow } from "../../theme";
import { StagePlayerProps } from "../NazoClusterQuizPlayer";

export const StageFinalPlayer: FC<StagePlayerProps> = (props) => {
  const { room, user, roomId, userId } = props;
  const stageFinal = room?.stageFinal;
  const prob = getProblemFinal(stageFinal?.probNumber ?? 1);

  const [serverTimeDiff, setServerTimeDiff] = useState<number>(0);
  const playerName = user?.name ?? "";
  const [serverTime, setServerTime] = useState<Date>();
  const start = stageFinal?.phase !== "waiting";
  const countDown = !start
    ? 30
    : stageFinal?.phase === "finished" || stageFinal?.phase === "calculated"
    ? 0
    : Math.max(
        0,
        (prob?.timeLimit ?? 30) -
          ((serverTime?.getTime() ?? 0) -
            (stageFinal?.startTime as Timestamp)?.toDate()?.getTime()) /
            1000
      );

  const explained =
    stageFinal?.phase === "explaining" || stageFinal?.phase === "calculated";
  const finished =
    stageFinal?.phase === "finished" ||
    stageFinal?.phase === "explaining" ||
    stageFinal?.phase === "calculated";

  useEffect(() => {
    if (!room || !user) {
      return;
    }
    if (!start) {
      return;
    }
    if (!playerName) {
      return;
    }
    getServerTime(playerName !== "" ? playerName ?? "player" : "player").then(
      (serverTime: Date) => {
        setServerTimeDiff(serverTime.getTime() - new Date().getTime());
      }
    );

    const interval = setInterval(() => {
      setServerTime(new Date(new Date().getTime() + (serverTimeDiff ?? 0)));
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, [start, playerName]);

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    // phase: waiting時に初期化
    if (!start) {
      setSubmitted(false);
    }
  }, [start]);

  const submit = (input: string) => {
    if (!roomId) return;
    if (!user) return;
    if (!prob) return;
    if (!start) return;
    if (finished) return;
    if (!serverTime) return;
    if (!userId) return;
    setSubmitted(true);
    updateDoc(doc(db, "nazoClusterQuiz/" + roomId + "/user/" + userId), {
      ["stageFinal." + stageFinal?.probNumber]: {
        answer: input,
        answerTime: serverTimestamp(),
      },
    });
  };

  return (
    <Grid container>
      <Grid item xs={4}>
        <div
          style={{
            width: "calc(100% - 4vw)",
            margin: "2vw",
            height: "10vh",
            borderRadius: "4vw",
            backgroundColor: mainBg,
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "8vw",
              fontWeight: "bold",
            }}
          >
            {prob?.problemNumberText}
          </p>
        </div>
      </Grid>

      <Grid item xs={8}>
        <div
          style={{
            width: "calc(100% - 4vw)",
            margin: "2vw",
            height: "10vh",
            borderRadius: "4vw",
            backgroundColor: mainBg,
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "left",
          }}
        >
          <p
            style={{
              fontSize: "6vw",
              fontWeight: "bold",
              marginLeft: "2vw",
              marginRight: "auto",
            }}
          >
            {prob?.genre}
          </p>
        </div>
      </Grid>

      <Grid item xs={3}>
        <div
          style={{
            backgroundColor: red,
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              margin: 0,
            }}
          >
            Aチーム
          </p>
        </div>
        <div
          style={{
            backgroundColor: "white",
            color: "black",
            fontWeight: "bold",
            border: "1vw solid #000",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              margin: 0,
            }}
          >
            {(room.stageFinal?.team[1].score ?? 0) === 0
              ? "脱落"
              : room.stageFinal?.team[1].score ?? 0}
          </p>
        </div>
      </Grid>
      <Grid item xs={3}>
        <div
          style={{
            backgroundColor: blue,
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              margin: 0,
            }}
          >
            Bチーム
          </p>
        </div>
        <div
          style={{
            backgroundColor: "white",
            color: "black",
            fontWeight: "bold",
            border: "1vw solid #000",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              margin: 0,
            }}
          >
            {(room.stageFinal?.team[2].score ?? 0) === 0
              ? "脱落"
              : room.stageFinal?.team[2].score ?? 0}
          </p>
        </div>
      </Grid>
      <Grid item xs={3}>
        <div
          style={{
            backgroundColor: green,
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              margin: 0,
            }}
          >
            Cチーム
          </p>
        </div>
        <div
          style={{
            backgroundColor: "white",
            color: "black",
            fontWeight: "bold",
            border: "1vw solid #000",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              margin: 0,
            }}
          >
            {(room.stageFinal?.team[3].score ?? 0) === 0
              ? "脱落"
              : room.stageFinal?.team[3].score ?? 0}
          </p>
        </div>
      </Grid>
      <Grid item xs={3}>
        <div
          style={{
            backgroundColor: yellow,
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              margin: 0,
            }}
          >
            Dチーム
          </p>
        </div>
        <div
          style={{
            backgroundColor: "white",
            color: "black",
            fontWeight: "bold",
            border: "1vw solid #000",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              margin: 0,
            }}
          >
            {(room.stageFinal?.team[4].score ?? 0) === 0
              ? "脱落"
              : room.stageFinal?.team[4].score ?? 0}
          </p>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          my: 3,
        }}
      />
      {!start && (
        <Grid item xs={12}>
          <div
            style={{
              width: "calc(100% - 4vw)",
              margin: "2vw",
              borderRadius: "2vw",
              backgroundColor: mainBg,
              color: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
            }}
          >
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/nazo-cluster-quiz/final/" +
                prob?.introFileName
              }
              style={{
                width: "calc(100% - 4vw)",
                margin: "2vw",
              }}
              alt="問題画像"
            />
          </div>
        </Grid>
      )}
      {start && (
        <>
          <Grid
            item
            xs={6}
            onClick={() => !finished && !submitted && submit("A")}
          >
            <div
              style={{
                ...choiceBox,
                backgroundColor: red,
                opacity:
                  (finished || submitted) &&
                  user.stageFinal?.[stageFinal?.probNumber ?? "1"]?.answer !==
                    "A"
                    ? 0.5
                    : 1,
              }}
            >
              A
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            onClick={() => !finished && !submitted && submit("B")}
          >
            <div
              style={{
                ...choiceBox,
                backgroundColor: blue,
                opacity:
                  (finished || submitted) &&
                  user.stageFinal?.[stageFinal?.probNumber ?? "1"]?.answer !==
                    "B"
                    ? 0.5
                    : 1,
              }}
            >
              B
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            onClick={() => !finished && !submitted && submit("C")}
          >
            <div
              style={{
                ...choiceBox,
                backgroundColor: green,
                opacity:
                  (finished || submitted) &&
                  user.stageFinal?.[stageFinal?.probNumber ?? "1"]?.answer !==
                    "C"
                    ? 0.5
                    : 1,
              }}
            >
              C
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            onClick={() => !finished && !submitted && submit("D")}
          >
            <div
              style={{
                ...choiceBox,
                backgroundColor: yellow,
                opacity:
                  (finished || submitted) &&
                  user.stageFinal?.[stageFinal?.probNumber ?? "1"]?.answer !==
                    "D"
                    ? 0.5
                    : 1,
              }}
            >
              D
            </div>
          </Grid>
        </>
      )}

      {start && (
        <Grid
          item
          xs={12}
          sx={{
            color: mainColor,
            fontSize: "2vw",
            fontWeight: "bold",
            textAlign: "center",
            margin: "2vw 0",
          }}
        >
          <p>{submitted ? "解答判定をお待ちください" : prob?.answerMessage}</p>
        </Grid>
      )}
    </Grid>
  );
};

const choiceBox: CSSProperties = {
  width: "calc(100% - 4vw)",
  height: "36vw",
  margin: "2vw",
  borderRadius: "2vw",
  color: "white",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "left",
  textAlign: "center",
  fontSize: "20vw",
};
