import { FC } from "react";

export type Mode = "blue" | "red" | "normal" | "green";

type MemberScreenProps = {
  mode: Mode;
  answer: string;
  name: string;
};

const theme = {
  blue: {
    backgroundColor: "blue",
    color: "white",
  },
  red: {
    backgroundColor: "red",
    color: "white",
  },
  green: {
    backgroundColor: "green",
    color: "white",
  },
  normal: {
    backgroundColor: "black",
    color: "black",
  },
};

export const MemberArea: FC<MemberScreenProps> = (props) => {
  const { mode } = props;
  const styles = theme[mode];
  return (
    <div
      style={{
        height: "28vh",
        border: `0.5vw solid #9eeef5`,
        backgroundColor: "#9eeef5",
        margin: " 0 1vw",
      }}
    >
      <div
        style={{
          fontSize: "1em",
          height: "5vh",
          border: `0.5vw solid #9eeef5`,
          backgroundColor: "#9eeef5",
          color: "#111",
          textAlign: "center",
        }}
      >
        <h2
          style={{
            margin: 0,
            fontSize: "1em",
          }}
        >
          {props.name}
        </h2>
      </div>
      <div
        style={{
          borderRadius: "0.5vw",
          backgroundColor: styles.backgroundColor,
          height: "20vh",
          color: styles.color,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "2em",
        }}
      >
        <h2
          style={{
            margin: 0,
            fontSize: "1em",
          }}
        >
          {props.answer}
        </h2>
      </div>
    </div>
  );
};
