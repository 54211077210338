import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Button, Checkbox, IconButton } from "@mui/material";
import { doc, increment, serverTimestamp, updateDoc } from "firebase/firestore";
import { CSSProperties, FC } from "react";
import { db } from "../../../..";
import { firstProblemList, getProblem1st } from "../../data/prob";
import { blue, green, red, yellow } from "../../theme";
import { StageProps } from "../AdminNazoClusterQuiz";

const genres = firstProblemList
  .filter((_, i) => i % 9 === 0)
  .map((prob) => prob.genre);

export const Stage1st: FC<StageProps> = (props) => {
  const { users, roomId, room } = props;
  const stage1 = room.stage1;

  const updateUser = (userId: string, teamId: number, teamNumber: number) => {
    if (!roomId) return;
    updateDoc(doc(db, "nazoClusterQuiz/" + roomId + "/user/" + userId), {
      teamId,
      teamNumber,
    });
  };

  const updateGenre = (genre: string) => {
    if (!roomId) return;
    if (!room) return;

    const probNumber = room.stage1?.genres?.[genre]?.probNumber || 1;
    updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
      "stage1.genre": genre,
      "stage1.probNumber": probNumber,
      ["stage1.genres." + genre]: {
        probNumber,
      },
      "stage1.phase": "waiting",
    });
  };

  const updatePhase = (phase: string) => {
    if (!roomId) return;
    if (!room) return;

    if (phase === "playing") {
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stage1.phase": phase,
        "stage1.startTime": serverTimestamp(),
      });
    }
    if (phase === "finished") {
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stage1.phase": phase,
        "stage1.endTime": serverTimestamp(),
      });
    }
    if (phase === "explaining") {
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stage1.phase": phase,
      });
    }
    if (phase === "calculated") {
      // every correct
      const teamSuccess = [1, 2, 3, 4].map((teamId) =>
        users
          .filter((u) => u.teamId === teamId)
          .every((u) => {
            const prob = getProblem1st(
              stage1?.probNumber ?? 1,
              u.teamNumber ?? 1,
              stage1?.genre ?? "ジャンル：国語"
            );
            const answer = prob?.answer ?? "";
            const userSubmit =
              stage1?.genre && room.stage1?.probNumber
                ? u.stage1?.[stage1?.genre]?.[room.stage1?.probNumber]
                : undefined;
            return userSubmit?.correct ?? userSubmit?.answer === answer;
          })
      );
      const defaultProb = getProblem1st(
        stage1?.probNumber ?? 1,
        1,
        stage1?.genre ?? "ジャンル：国語"
      );
      const teamScores = teamSuccess.map((success, i) =>
        success ? defaultProb?.score ?? 10 : 0
      );

      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stage1.phase": phase,
        "stage1.calcTime": serverTimestamp(),
        ["stage1.team.1." + stage1?.genre + "-" + stage1?.probNumber]: {
          score: teamScores[0],
        },
        ["stage1.team.2." + stage1?.genre + "-" + stage1?.probNumber]: {
          score: teamScores[1],
        },
        ["stage1.team.3." + stage1?.genre + "-" + stage1?.probNumber]: {
          score: teamScores[2],
        },
        ["stage1.team.4." + stage1?.genre + "-" + stage1?.probNumber]: {
          score: teamScores[3],
        },
      });
    }
  };

  const updateOpen = (userId: string) => {
    if (!roomId) return;
    if (!room) return;
    updateDoc(doc(db, "nazoClusterQuiz/" + roomId + "/user/" + userId), {
      ["stage1." + stage1?.genre + "." + stage1?.probNumber + ".open"]: true,
    });
  };

  const updateCorrect = (userId: string, correct: boolean) => {
    if (!roomId) return;
    if (!room) return;
    updateDoc(doc(db, "nazoClusterQuiz/" + roomId + "/user/" + userId), {
      ["stage1." + stage1?.genre + "." + stage1?.probNumber + ".correct"]:
        correct,
    });
  };

  const updateNextProblem = () => {
    if (!roomId) return;
    if (!room) return;
    if (!stage1) return;

    updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
      "stage1.probNumber": increment(1),
      ["stage1.genres." + stage1.genre]: {
        probNumber: increment(1),
      },
      "stage1.phase": "waiting",
    });
  };

  return (
    <>
      <h2>ステージ1</h2>
      <table
        style={{
          margin: "auto",
          border: "1px solid #000",
          borderCollapse: "collapse",
          borderSpacing: 0,
          fontSize: "100%",
        }}
      >
        <tr style={trStyle}>
          <th style={thStyle}>ジャンル</th>
          <th style={thStyle}>第１問</th>
          <th style={thStyle}>第２問</th>
          <th style={thStyle}>第３問</th>
        </tr>
        {firstProblemList
          .filter((_, i) => i % 9 === 0)
          .map((prob, i) => (
            <tr key={"genre-" + i}>
              <th
                style={{
                  ...thStyle,
                  ...teamHeaderStyle,
                  backgroundColor:
                    room.stage1?.genre === genres[i] ? "red" : "#444",
                  cursor: "pointer",
                }}
                onClick={() => {
                  updateGenre(genres[i]);
                }}
              >
                {prob.genre}
              </th>
              {[0, 1, 2].map((probIndex) => (
                <td
                  key={i}
                  style={{
                    ...tdStyle,
                    ...(stage1?.probNumber === probIndex + 1 &&
                    stage1?.genre === genres[i]
                      ? cursorStyle
                      : {}),
                    ...(stage1?.team[1]?.[genres[i] + "-" + (probIndex + 1)]
                      ? { backgroundColor: "lightblue" }
                      : {}),
                  }}
                >
                  {stage1?.probNumber === probIndex + 1 &&
                  stage1?.genre === genres[i] ? (
                    (stage1.phase ?? "waiting") === "waiting" ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            updatePhase("playing");
                          }}
                        >
                          スタート
                        </Button>
                      </>
                    ) : stage1.phase === "playing" ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            updatePhase("finished");
                          }}
                        >
                          解答終了
                        </Button>
                      </>
                    ) : stage1.phase === "finished" ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            updatePhase("explaining");
                          }}
                        >
                          解説表示
                        </Button>
                      </>
                    ) : stage1.phase === "explaining" ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            updatePhase("calculated");
                          }}
                        >
                          得点集計
                        </Button>
                      </>
                    ) : stage1.phase === "calculated" ? (
                      <>
                        {probIndex === 2 ? (
                          <p>次のジャンルを選択してください</p>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              updateNextProblem();
                            }}
                          >
                            次の問題へ
                          </Button>
                        )}
                      </>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </td>
              ))}
            </tr>
          ))}
      </table>

      <Box sx={{ my: 2 }} />

      <table
        style={{
          margin: "auto",
          border: "1px solid #000",
          borderCollapse: "collapse",
          borderSpacing: 0,
          fontSize: "100%",
        }}
      >
        <tr style={trStyle}>
          <th style={thStyle}>チーム</th>
          <th style={thStyle}>1st</th>
          <th style={thStyle}>2nd</th>
          <th style={thStyle}>3rd</th>
          <th style={thStyle}>一括</th>
          <th style={thStyle}>正解</th>
          <th style={thStyle}>スコア</th>
        </tr>
        {[1, 2, 3, 4].map((teamId) => (
          <tr key={"team-" + teamId}>
            <th
              style={{
                ...thStyle,
                ...teamHeaderStyle,
                backgroundColor: [red, blue, green, yellow][teamId - 1],
              }}
            >
              チーム{teamId}
            </th>
            {[1, 2, 3].map((teamNumber) => (
              <td
                key={teamNumber}
                style={{
                  ...tdStyle,
                }}
              >
                {users
                  .filter(
                    (u) => u.teamId === teamId && u.teamNumber === teamNumber
                  )
                  .map((u, i) => {
                    const userSubmit =
                      stage1?.genre && room.stage1?.probNumber
                        ? u.stage1?.[stage1?.genre]?.[room.stage1?.probNumber]
                        : undefined;
                    const userAnswer = userSubmit?.answer ?? "";

                    const prob = getProblem1st(
                      stage1?.probNumber ?? 1,
                      u.teamNumber ?? 1,
                      stage1?.genre ?? "ジャンル：国語"
                    );
                    const answer = prob?.answer ?? "";
                    return (
                      <>
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "#222",
                            color: "#fff",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              padding: 0,
                              textAlign: "center",
                            }}
                          >
                            {userSubmit?.open ? "オープン" : "クローズ中"}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "#222",
                            color: "#fff",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              textAlign: "center",
                            }}
                          >
                            『{userAnswer}』
                          </p>
                          <p
                            key={i}
                            style={{
                              textAlign: "center",
                              margin: 0,
                              fontSize: "80%",
                            }}
                          >
                            {u.name}
                          </p>
                        </div>
                        <p
                          style={{
                            margin: 0,
                          }}
                        >
                          <IconButton
                            aria-label="wrong"
                            color={
                              userSubmit?.correct ?? answer === userAnswer
                                ? "inherit"
                                : "info"
                            }
                            onClick={() => {
                              updateCorrect(u.id, false);
                            }}
                          >
                            <CancelIcon />
                          </IconButton>
                          <IconButton
                            aria-label="correct"
                            color={
                              userSubmit?.correct ?? answer === userAnswer
                                ? "error"
                                : "inherit"
                            }
                            onClick={() => {
                              updateCorrect(u.id, true);
                            }}
                          >
                            <CheckCircleOutlineIcon />
                          </IconButton>
                        </p>
                        {!userSubmit?.open && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              updateOpen(u.id);
                            }}
                          >
                            オープン
                          </Button>
                        )}
                      </>
                    );
                  })}
              </td>
            ))}
            <td
              style={{
                ...tdStyle,
              }}
            >
              {users
                .filter((u) => u.teamId === teamId)
                .some((u) => {
                  const userSubmit =
                    stage1?.genre && room.stage1?.probNumber
                      ? u.stage1?.[stage1?.genre]?.[room.stage1?.probNumber]
                      : undefined;
                  return !userSubmit?.open;
                }) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    users
                      .filter((u) => u.teamId === teamId)
                      .forEach((u) => {
                        updateOpen(u.id);
                      });
                  }}
                >
                  オープン
                </Button>
              )}
            </td>
            <td
              style={{
                ...tdStyle,
              }}
            >
              <Checkbox
                checked={users
                  .filter((u) => u.teamId === teamId)
                  .every((u) => {
                    const prob = getProblem1st(
                      stage1?.probNumber ?? 1,
                      u.teamNumber ?? 1,
                      stage1?.genre ?? "ジャンル：国語"
                    );
                    const answer = prob?.answer ?? "";
                    const userSubmit =
                      stage1?.genre && room.stage1?.probNumber
                        ? u.stage1?.[stage1?.genre]?.[room.stage1?.probNumber]
                        : undefined;
                    return userSubmit?.correct ?? userSubmit?.answer === answer;
                  })}
              />
            </td>
            <td
              style={{
                ...tdStyle,
              }}
            >
              {Object.values(stage1?.team?.[teamId] ?? {}).reduce(
                (acc, value) => acc + value.score,
                0
              )}
            </td>
          </tr>
        ))}
      </table>
      <Box sx={{ my: 8 }} />
    </>
  );
};

const trStyle: CSSProperties = {
  border: "1px solid #000",
};

const thStyle: CSSProperties = {
  border: "1px solid #000",
  backgroundColor: "#444",
  color: "white",
};

const tdStyle: CSSProperties = {
  border: "1px solid #000",
  minWidth: "100px",
  color: "#111",
};

const teamHeaderStyle: CSSProperties = {
  color: "white",
};

const cursorStyle: CSSProperties = {
  boxShadow: "0 0 0 2px #e89a00 inset",
};
