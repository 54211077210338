import { Box, Button } from "@mui/material";
import { doc, increment, updateDoc } from "firebase/firestore";
import { FC, useState } from "react";
import { db } from "../../../..";
import { blue, green, red, yellow } from "../../theme";
import { StageProps } from "../AdminNazoClusterQuiz";

type ProbCursor = {
  genre: number;
  probNumber: number;
};

export const Stage2nd: FC<StageProps> = (props) => {
  const { users, roomId, room } = props;

  const stage2 = room.stage2;

  const [genreIndex, setGenreIndex] = useState<number>(0);

  const updateUser = (userId: string, teamId: number, teamNumber: number) => {
    if (!roomId) return;
    updateDoc(doc(db, "nazoClusterQuiz/" + roomId + "/user/" + userId), {
      teamId,
      teamNumber,
    });
  };

  const updateCorrect = (teamId: number, teamNumber: number) => {
    if (!roomId) return;
    if (!room) return;
    if (!stage2) return;

    updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
      "stage2.probNumber": increment(1),
      "stage2.phase": "waiting",
    });
  };
  const updateWrong = (teamId: number, teamNumber: number) => {
    // other teams +10
    if (!roomId) return;
    if (!room) return;
    if (!stage2) return;

    const user = users.find(
      (u) => u.teamId === teamId && u.teamNumber === teamNumber
    );

    updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
      "stage2.probNumber": increment(1),
      "stage2.phase": "waiting",
      "stage2.team.1.score":
        teamId !== 1 ? increment(10) : stage2.team[1].score,
      "stage2.team.2.score":
        teamId !== 2 ? increment(10) : stage2.team[2].score,
      "stage2.team.3.score":
        teamId !== 3 ? increment(10) : stage2.team[3].score,
      "stage2.team.4.score":
        teamId !== 4 ? increment(10) : stage2.team[4].score,
      "stage2.wrongUser": {
        ...(stage2.wrongUser ?? {}),
        [teamId + "-" + teamNumber]: true,
      },
    });
  };

  const resetWrong = () => {
    if (!roomId) return;
    if (!room) return;
    if (!stage2) return;

    updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
      "stage2.wrongUser": {},
    });
  };

  return (
    <>
      <h2>ステージ2</h2>
      <Button
        variant="contained"
        onClick={() => {
          resetWrong();
        }}
      >
        ドボンリセット
      </Button>
      <table
        style={{
          margin: "auto",
          border: "1px solid #000",
          borderCollapse: "collapse",
          borderSpacing: 0,
          fontSize: "100%",
        }}
      >
        <tr style={trStyle}>
          <th style={thStyle}>チーム</th>
          <th style={thStyle}>1st</th>
          <th style={thStyle}>2nd</th>
          <th style={thStyle}>3rd</th>
          <th style={thStyle}>スコア</th>
        </tr>
        {[1, 2, 3, 4].map((teamId) => (
          <tr key={teamId}>
            <th
              style={{
                ...thStyle,
                ...teamHeaderStyle,
                backgroundColor: [red, blue, green, yellow][teamId - 1],
              }}
            >
              チーム{teamId}
            </th>
            {[1, 2, 3].map((teamNumber) => (
              <td
                key={teamNumber}
                style={{
                  ...tdStyle,
                }}
              >
                {users
                  .filter(
                    (u) => u.teamId === teamId && u.teamNumber === teamNumber
                  )
                  .map((u, i) => (
                    <>
                      <p
                        key={i}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {stage2?.wrongUser?.[teamId + "-" + teamNumber]
                          ? "×"
                          : ""}
                        {u.name}
                      </p>
                    </>
                  ))}
                {((((room.stage2?.probNumber ?? 1) - 1) / 4) | 0) % 3 ===
                  teamNumber - 1 &&
                ((room.stage2?.probNumber ?? 1) - 1) % 4 === teamId - 1 ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        updateCorrect(teamId, teamNumber);
                      }}
                    >
                      次へ
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        updateWrong(teamId, teamNumber);
                      }}
                      sx={{
                        ml: 2,
                      }}
                    >
                      ドボン
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </td>
            ))}
            <td
              style={{
                ...tdStyle,
              }}
            >
              {stage2?.team[teamId].score ?? 0}
            </td>
          </tr>
        ))}
      </table>
      <Box sx={{ my: 8 }} />
    </>
  );
};

const trStyle = {
  border: "1px solid #000",
};

const thStyle = {
  border: "1px solid #000",
  backgroundColor: "#444",
  color: "white",
};

const tdStyle = {
  border: "1px solid #000",
  minWidth: "100px",
  color: "#111",
};

const teamHeaderStyle = {
  color: "white",
};
