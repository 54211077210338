import { Grid } from "@mui/material";
import { TeamArea } from "../common/TeamArea";
import { teams } from "../NazoClusterQuizScreen";
import { NazoClusterQuizRoom, NazoClusterQuizUser } from "../../types";
import { useMemo } from "react";
import { Mode } from "../common/MemberArea";
import { WithId } from "../../../../hooks/useCollection";
import { getProblem4th } from "../../data/prob";

export const Stage4thScreen = ({
  room,
  users,
}: {
  room: NazoClusterQuizRoom;
  users: WithId<NazoClusterQuizUser>[];
}) => {
  const names = useMemo(
    () =>
      [1, 2, 3, 4].map((teamId) =>
        [1, 2, 3].map(
          (playerId) => room.teams?.[teamId]?.number?.[playerId]?.name ?? ""
        )
      ),
    [room.teams]
  );
  const playerIds = useMemo(
    () =>
      [1, 2, 3, 4].map((teamId) =>
        [1, 2, 3].map(
          (playerId) => room.teams?.[teamId]?.number?.[playerId]?.id ?? ""
        )
      ),
    [room.teams]
  );
  const playerAnswers = playerIds.map((team) =>
    team.map((id) => {
      const probNumber = room.stage4?.probNumber ?? 1;
      const answer = users.find((user) => user.id === id)?.stage4?.[probNumber];
      if (answer === undefined || !answer.open) {
        return "";
      }
      return answer.answer;
    })
  );
  const totalScores = useMemo(
    () =>
      [1, 2, 3, 4].map((teamId) => room?.stage4?.team?.[teamId]?.score ?? 0),
    [room.stage4]
  );
  const playerColors: Mode[][] = useMemo(() => {
    const stage4 = room.stage4;
    const fastest: (
      | {
          userId: string;
          timestamp: Date;
        }
      | undefined
    )[] = [undefined, undefined, undefined, undefined];
    users.forEach((u) => {
      const prob = getProblem4th(stage4?.probNumber ?? 1);
      const answer = prob?.answer ?? [];
      const correctStatus = stage4?.probNumber
        ? u.stage4?.[stage4?.probNumber]?.correctStatus ??
          answer.findIndex((e) =>
            e.some(
              (ans) => ans === (u.stage4?.[stage4?.probNumber]?.answer ?? "")
            )
          )
        : undefined;

      const userSubmit = room.stage4?.probNumber
        ? u.stage4?.[room.stage4?.probNumber]
        : undefined;
      if (
        correctStatus !== undefined &&
        correctStatus !== -1 &&
        userSubmit !== undefined
      ) {
        if (fastest[correctStatus] === undefined) {
          fastest[correctStatus] = {
            userId: u.id,
            timestamp: userSubmit.answerTime.toDate(),
          };
        } else {
          if (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
            fastest[correctStatus]?.timestamp! > userSubmit.answerTime.toDate()
          ) {
            fastest[correctStatus] = {
              userId: u.id,
              timestamp: userSubmit.answerTime.toDate(),
            };
          }
        }
      }
    });
    console.log(fastest);

    return playerIds.map((team) =>
      team.map((id) => {
        const user = users.find((user) => user.id === id);
        if (!user) {
          return "normal";
        }
        const userSubmit = stage4?.probNumber
          ? user?.stage4?.[stage4?.probNumber]
          : undefined;
        if (userSubmit === undefined || !userSubmit.open) {
          return "normal";
        }
        const prob = getProblem4th(stage4?.probNumber ?? 1);
        const answer = prob?.answer ?? [];
        const correctStatus =
          userSubmit.correctStatus ??
          answer.findIndex((e) => e.some((ans) => ans === userSubmit.answer));
        console.log(correctStatus);
        if (correctStatus === -1) {
          return "blue";
        }
        if (fastest[correctStatus]?.userId === user.id) {
          return "red";
        }
        return "green";
      })
    );
  }, [playerIds, room.stage4, users]);

  //TODO 色の設定
  return (
    <Grid container>
      {teams.map((team, i) => (
        <Grid item xs={6} key={i}>
          <TeamArea
            teamName={team.teamName}
            teamColor={team.teamColor}
            playerNames={names[i]}
            playerColors={playerColors[i]}
            playerAnswers={playerAnswers[i]}
            totalScore={totalScores[i]}
          />
        </Grid>
      ))}
    </Grid>
  );
};
