import { Box, Button } from "@mui/material";
import {
  doc,
  increment,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { FC, useState } from "react";
import { db } from "../../../..";
import { blue, green, red, yellow } from "../../theme";
import { StageProps } from "../AdminNazoClusterQuiz";
import { getProblemFinal } from "../../data/prob";

type ProbCursor = {
  genre: number;
  probNumber: number;
};

export const StageFinal: FC<StageProps> = (props) => {
  const { users, roomId, room } = props;
  const stageFinal = room.stageFinal;

  const [genreIndex, setGenreIndex] = useState<number>(0);

  const updateUser = (userId: string, teamId: number, teamNumber: number) => {
    if (!roomId) return;
    updateDoc(doc(db, "nazoClusterQuiz/" + roomId + "/user/" + userId), {
      teamId,
      teamNumber,
    });
  };

  const updateNextProblem = () => {
    if (!roomId) return;
    if (!room) return;
    if (!stageFinal) return;

    updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
      "stageFinal.probNumber": increment(1),
      "stageFinal.phase": "waiting",
    });
  };

  const updatePhase = (phase: string) => {
    if (!roomId) return;
    if (!room) return;

    if (phase === "playing") {
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stageFinal.phase": phase,
        "stageFinal.startTime": serverTimestamp(),
      });
    }
    if (phase === "finished") {
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stageFinal.phase": phase,
        "stageFinal.endTime": serverTimestamp(),
      });
    }
  };

  const updateCorrect = (teamId: number, correct: boolean) => {
    if (!roomId) return;
    if (!stageFinal) return;
    updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
      "stageFinal.team.1.score":
        teamId === 1
          ? correct
            ? stageFinal.team[1].score
            : stageFinal.team[1].score < 10
            ? 0
            : Math.ceil(stageFinal.team[1].score / 2)
          : correct
          ? Math.max(stageFinal.team[1].score - 10, 0)
          : stageFinal.team[1].score,
      "stageFinal.team.2.score":
        teamId === 2
          ? correct
            ? stageFinal.team[2].score
            : stageFinal.team[2].score < 10
            ? 0
            : Math.ceil(stageFinal.team[2].score / 2)
          : correct
          ? Math.max(stageFinal.team[2].score - 10, 0)
          : stageFinal.team[2].score,
      "stageFinal.team.3.score":
        teamId === 3
          ? correct
            ? stageFinal.team[3].score
            : stageFinal.team[3].score < 10
            ? 0
            : Math.ceil(stageFinal.team[3].score / 2)
          : correct
          ? Math.max(stageFinal.team[3].score - 10, 0)
          : stageFinal.team[3].score,
      "stageFinal.team.4.score":
        teamId === 4
          ? correct
            ? stageFinal.team[4].score
            : stageFinal.team[4].score < 10
            ? 0
            : Math.ceil(stageFinal.team[4].score / 2)
          : correct
          ? Math.max(0, stageFinal.team[4].score - 10)
          : stageFinal.team[4].score,
    });
  };

  if (!stageFinal) {
    return <Box>loading...</Box>;
  }

  return (
    <>
      <h2>ステージFinal</h2>
      <div style={{ textAlign: "center", marginBottom: "8px" }}>
        <p>問題管理</p>
        {(stageFinal?.phase ?? "waiting") === "waiting" ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updatePhase("playing");
              }}
            >
              スタート
            </Button>
          </>
        ) : stageFinal.phase === "playing" ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updatePhase("finished");
              }}
            >
              解答終了
            </Button>
          </>
        ) : stageFinal?.phase === "finished" ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updateNextProblem();
              }}
            >
              次の問題へ
            </Button>
          </>
        ) : (
          <></>
        )}
      </div>
      <table
        style={{
          margin: "auto",
          border: "1px solid #000",
          borderCollapse: "collapse",
          borderSpacing: 0,
          fontSize: "100%",
        }}
      >
        <tr style={trStyle}>
          <th style={thStyle}>チーム</th>
          <th style={thStyle}>スコア</th>
          <th style={thStyle}>スコア操作</th>
        </tr>
        {[1, 2, 3, 4].map((teamId) => (
          <tr key={teamId}>
            <th
              style={{
                ...thStyle,
                ...teamHeaderStyle,
                backgroundColor: [red, blue, green, yellow][teamId - 1],
              }}
            >
              チーム{teamId}
            </th>
            <td
              style={{
                ...tdStyle,
              }}
            >
              {(stageFinal.team[teamId]?.score ?? 0) === 0
                ? "脱落"
                : stageFinal.team[teamId]?.score ?? 0}
            </td>
            <td
              style={{
                ...tdStyle,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  updateCorrect(teamId, true);
                }}
              >
                正解
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  updateCorrect(teamId, false);
                }}
                sx={{ ml: 2 }}
              >
                不正解
              </Button>
            </td>
          </tr>
        ))}
      </table>
      <Box>
        {users
          .map((user) => {
            return {
              user,
              userProb: user.stageFinal?.[stageFinal.probNumber ?? "1"],
            };
          })
          .filter(({ userProb }) => userProb?.answerTime)
          .sort(
            ({ userProb: a }, { userProb: b }) =>
              (a?.answerTime as Timestamp)?.toDate().getTime() -
              (b?.answerTime as Timestamp)?.toDate().getTime()
          )
          .map(({ user, userProb }, i) => {
            return (
              <Box key={user.name}>
                <p>
                  {i + 1}.チーム{user.teamId} {user.name}: {userProb?.answer}
                </p>
              </Box>
            );
          })}
      </Box>
      <Box sx={{ my: 8 }} />
    </>
  );
};

const trStyle = {
  border: "1px solid #000",
};

const thStyle = {
  border: "1px solid #000",
  backgroundColor: "#444",
  color: "white",
};

const tdStyle = {
  border: "1px solid #000",
  minWidth: "100px",
  color: "#111",
};

const teamHeaderStyle = {
  color: "white",
};
